.ant-transfer-list-header-selected{
    display: none !important;
}

.ShareButton, .UnshareButton, .SaveButton {
    margin: 16px;
}

.ShareButton{
    margin-top: 48px;
    position: fixed;
    top: 50%;
}