.PartnerCard{
    display: inline-block;
    width: 300px;
    /* height: 200px; */
    margin: 24px;
    box-shadow: 0px 0px 4px #eee;
}

.PartnerLogo{
    height: 60px;
    display:block;
    margin:auto;
}