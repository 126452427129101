.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

body {
  background-color: white;
}

.Logo {
  height: 30px;
  margin: 20px;
  display: flex;
  background-image: url("../src/assets/images/logo/pnta_mapping_logo_white.png");
  background-size: 190px;
  background-repeat: no-repeat;
}

.SubTitle{
  margin: 20px;
  color: white !important;
  font-size: 12px;
}